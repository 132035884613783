<template>
  <div class="container">
    <section>
      <v-row class="d-flex align-center">
        <v-col cols="12" md="7">
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Proteção do Patrimônio Construído"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                As Capelas de São Bento e de Nossa Senhora das Mercês foram
                inventariadas no ano de 2004 pela Prefeitura Municipal de
                Mariana, integrando o Inventário de Proteção do Acervo Cultural
                – IPAC.
              </p>
              <p>
                Após o rompimento da barragem de Fundão, diante dos riscos que
                os bens culturais existentes na área atingida ficaram sujeitos,
                o COMPAT (Conselho Municipal de Patrimônio) propôs processos de
                tombamento de monumentos isolados e, posteriormente, dos núcleos
                urbanos, com o objetivo de ampliar o perímetro de preservação
                dos bens culturais destas localidades. O instrumento do
                Tombamento tornou-se, desta forma, um elemento condutor da
                resistência de parte da comunidade atingida frente à situação de
                risco por causa de saques e depredações.
              </p>
              <p>
                Neste sentido, em 04 de novembro de 2016, foi tombada a Capela
                de Nossa Senhora das Mercês, inscrita no Livro do Tombo por seu
                valor arquitetônico, urbanístico, artístico e histórico. Em 28
                de abril de 2016, o Conselho Municipal de Patrimônio aprovou o
                Tombamento Provisório do núcleo urbano de Bento Rodrigues,
                conforme Deliberação do COMPAT. Ressalta-se que a decisão do
                conselho municipal caracteriza-se como medida protetiva dos
                núcleos severamente atingidos, diante da falta de providências
                da empresa Samarco/Vale/BHP na proteção dos bens edificados
                soterrados, inclusive remanescentes de estruturas setecentista,
                com o objetivo ainda de evitar novos danos ao patrimônio
                atingido e conduzir adequadamente as questões do território.
                Desta forma, o conselho municipal reconheceu o valor cultural da
                comunidade, que resguarda bens significativos.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_cultura_bento_02.png"
          ></v-img>
        </v-col>
      </v-row>
    </section>
    <section>
      <row class="d-flex justify-center align-center">
        <v-col cols="12" md="8">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                A ação do COMPAT atende também à necessidade de se
                patrimonializar oficialmente o testemunho do mais grave dano
                ambiental do país, servindo de exemplo para que as atividades
                mineradoras não destruam territórios e não tirem a vida de mais
                pessoas. O conselho municipal reforça a iniciativa do grupo
                coordenado pelo professor Leonardo Castriota, presidente do
                Comitê Brasileiro do Conselho Internacional de Monumentos e
                Sítios (ICOMOS), em produzir o documento denominado Declaração
                de Significância, apresentado em maio de 2017 no Simpósio do
                ICOMOS em Belo Horizonte. O texto sintetiza os valores do
                subdistrito de Bento Rodrigues e ainda os laços sociais
                atribuídos a este território, mesmo devastado, que se mantém
                entre a comunidade atingida.
              </p>
              <p>
                A Capela de Nossa Senhora das Mercês foi protegida em âmbito
                estadual pelo IEPHA, com tombamento aprovado em 17 de outubro de
                2018. A construção desta capela ocorreu, provavelmente entre
                1750 e 1815, seguida da criação da Irmandade das Mercês no
                povoado. O cemitério anexo é um elemento fortemente ligado às
                associações religiosas mercedárias. Situada em cota elevada em
                Bento Rodrigues, destruída pelo rompimento da Barragem de Fundão
                em 2015, a Capela das Mercês foi uma das poucas edificações não
                alcançadas pela lama. Após o desastre–crime, passou de capela
                secundária à principal edificação de uso comunitário, onde
                acontecem reuniões não só religiosas, mas também festivas e
                sociais. Sua implantação apresenta harmonia com a paisagem, e a
                capela pode ser interpretada como símbolo de resistência e da
                capacidade de resiliência da comunidade.
              </p>
              <p style="text-aling: center; text-align: center">
                <strong>Autora: Ana Paula Alves Ferreira<sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
      </row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <strong>Referências Bibliográficas</strong>
            </p>
            <p>
              CASTRIOTA, Leonardo Barci. Dossiê de Tombamento de Bento
              Rodrigues. UFMG. Belo Horizonte, 2019. Disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento"
                class="pink--text"
                >https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento</a
              >. Acesso em: 30 mar. 2022.
            </p>
            <p>
              PÓLEN Consultoria Patrimônio e Projetos Ltda. Diagnóstico
              preliminar dos bens culturais identificados no território atingido
              em Mariana pelo rompimento da barragem de Fundão. Mariana, 2017.
            </p>
            <p>
              TEIXEIRA, Vanessa Cerqueira. Fé e cultura barroca sob o manto
              mercedário: devoção, religiosidade e vivências a partir da
              irmandade de Nossa Senhora das Mercês de Mariana (1749-1822). 220
              f. 2017. Dissertação (Mestrado em História) — Instituto de
              Ciências Humanas, Universidade Federal de Juiz de Fora Juiz de
              Fora-MG, 2017.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
section {
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
